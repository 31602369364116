<template>
  <h3 class="bg-text">404</h3>
  <div class="fof-wrapper">
    <div class="fof-holder">
      <img src="@/assets/images/logo.png" alt="" class="logo" />
      <h4><span>Page Not Found</span></h4>
      <h5>The requested url is not found on this server</h5>
      <router-link to="/" class="btn-home">Go back to home page</router-link>
    </div>
  </div>
</template>

<style scoped lang="scss">
.bg-text {
  font-size: 400px;
  line-height: 1;
  font-weight: 700;
  color: #e8e8e8;
  position: fixed;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.fof-wrapper {
  background: #f0f0f0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .fof-holder {
    padding: 20px;
    width: 600px;
    margin: auto;
    text-align: center;
    z-index: 1;
    .logo {
      width: 70px;
      display: block;
      margin: auto;
      margin-bottom: 50px;
    }

    h4 {
      font-size: 42px;
      color: #224c5b;
      font-weight: 700;
    }
    h5 {
      font-size: 21px;
      margin-bottom: 20px;
    }
    .btn-home {
      padding: 12px 30px;
      border-radius: 4px;
      border: none;
      background: #224c5b;
      color: #fff;
      display: inline-block;
      text-decoration: none;
      transition: all 0.2s ease-in;
      &:hover {
        box-shadow: 0 20px 30px -11px rgba(0, 0, 0, 0.2%);
        transform: translate(0px, -5px);
      }
    }
  }
}
</style>
